import axios from 'axios';
import store from '../../store';

const domainAPI = store.state.domainAPI;

// Get all staging request
const cus_stagingService_GetAllSR = (data) =>
  axios.get(domainAPI + '/api/customer/staging-request', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      includes: 'requestItems.additionalServices.service,billing,requestItems.corrections,style,allBillings',
      status: data.status,
      page: data.page,
    },
  });

// Get staging request data
const cus_stagingService_GetSRData = (data) =>
  axios.get(domainAPI + `/api/customer/staging-request/${data.id}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

// Get all staging request items
const cus_stagingService_GetAllSRItems = (data) =>
  axios.get(domainAPI + '/api/customer/request-item/get-sr-request-items', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      sr_id: data.id,
      includes: 'stagingRequest.billing,roomArea,furnitures.furniture,additionalOptions.additionalOption,corrections,additionalAllOptions.additionalOption,additionalConfirmOptions.additionalOption',
      paginated: data.paginated,
      page_size: data.paginated,
      page: data.page,
      billing_id: data.billing_id,
    },
  });

// Get all rooms
const cus_stagingService_GetRooms = (data) =>
  axios.get(domainAPI + '/api/reference/room-types', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

// Get office rooms
const cus_stagingService_GetOfficeRooms = (data) =>
axios.get(domainAPI + '/api/reference/office-room-types', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
});

// Get office rooms
const cus_stagingService_GetAllRooms = (data) =>
axios.get(domainAPI + '/api/reference/all-room-types', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
});

// Get all furnishing styles
const cus_stagingService_GetFunishingStyle = (data) =>
  axios.get(domainAPI + '/api/reference/furnishing-styles', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

const cus_stagingService_GetOfficeFunishingStyle = (data) =>
  axios.get(domainAPI + '/api/reference/furnishing-office-styles', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

const cus_stagingService_GetAllFunishingStyle = (data) =>
  axios.get(domainAPI + '/api/reference/furnishing-all-styles', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });


// Get all furnitures
const cus_stagingService_GetAllFurnitures = (data) =>
  axios.get(domainAPI + '/api/reference/furnitures', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

// Get all furnishing styles
const cus_stagingService_GetAditionalOptions = (data) =>
  axios.get(domainAPI + '/api/reference/options', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

// Get all furnishing styles
const cus_stagingService_GetConfirmAditionalOptions = (data) =>
axios.get(domainAPI + '/api/reference/confirm/options', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
  params: {
    request_item_id: data.request_item_id,
  },
});

  // Get digital renovation furnishing styles
  const cus_stagingService_GetDigitalRenovationOptions = (data) =>
    axios.get(domainAPI + '/api/reference/digital-renovation-options', {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  
  // Get digital renovation furnishing styles
  const cus_stagingService_GetConfirmDigitalRenovationOptions = (data) =>
    axios.get(domainAPI + '/api/reference/confirm/digital-renovation-options', {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      params: {
        request_item_id: data.request_item_id,
      },
    });

  // Update staging request - update item
  const cus_stagingService_corrent_UpdateConfirmOptions = (data) =>
  axios.put(
    domainAPI + `/api/customer/request-item/update-confirm-options`,
    {
      items: data.items,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Get all furnishing styles
const cus_stagingService_GetDeleteAditionalOptions = (data) =>
axios.get(domainAPI + '/api/reference/delete-options', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
});

// Get all furnishing styles
const cus_stagingService_GetConfirmDeleteAditionalOptions = (data) =>
axios.get(domainAPI + '/api/reference/confirm/delete-options', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
  params: {
    request_item_id: data.request_item_id,
  },
});

// Get all furnishing styles
const cus_stagingService_GetAllAditionalOptions = (data) =>
axios.get(domainAPI + '/api/reference/all-options', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
});

// Get house layout
const cus_stagingService_GetHouseLayout = (data) =>
axios.get(domainAPI + '/api/reference/house-layout', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
});

// Get all Billings
const cus_stagingService_GetAllBilling = (data) =>
  axios.get(domainAPI + '/api/customer/billing', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      page: data.page,
    },
  });

// Create staging request
const cus_stagingService_CreateSR = (data) =>
  axios.post(
    domainAPI + '/api/customer/staging-request',
    {
      furnishing_style_id: data.furnishing_style_id,
      request_category: data.request_category
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Create staging request - confirm delivery
const cus_stagingService_CreateSRConfirmDelivery = (data) =>
  axios.post(
    domainAPI + `/api/customer/staging-request/${data.id}/confirm`,
    {},
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Create staging request - checkout details
const cus_stagingService_CreateSRChekoutDetails = (data) =>
  axios.post(
    domainAPI + `/api/customer/staging-request/${data.id}/checkout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Create staging request - checkout details
const cus_stagingService_CreateConfirmChekoutDetails = (data) =>
  axios.post(
    domainAPI + `/api/customer/staging-request/confirm/${data.id}/checkout`,
    {
      confirm_count: data.confirm_count,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Create staging request - checkout details
const cus_stagingService_CreateCR = (data) =>
  axios.post(
    domainAPI + '/api/customer/correction-request',
    {
      items: data.items,
      request_base_dir: data.request_base_dir,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Update staging request - style ID
const cus_stagingService_UpdateSRStyleID = (data) =>
  axios.put(
    domainAPI + `/api/customer/staging-request/${data.id}`,
    {
      furnishing_style_id: data.furnishing_style_id,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Update staging request - add item
const cus_stagingService_UpdateSRAddItems = (data, formData) =>
  axios.post(domainAPI + '/api/customer/request-item', formData, {
    headers: {
      Authorization: `Bearer ${data.token}`,
      'Content-Type': 'multipart/form-data',
    },
    params: {
      includes: 'stagingRequest,roomArea,furnitures.furniture,additionalOptions.additionalOption,corrections',
    }
  });

const cus_stagingService_UpdateDrawing = (data, formatData) =>
  axios.post(domainAPI + '/api/customer/request-item/drawing', formatData, {
    headers: {
      Authorization: `Bearer ${data.token}`,
      'Content-Type': 'multipart/form-data',
    },
    params: {
    }
  });

// Update staging request - update item
const cus_stagingService_UpdateSRUpdateItems = (data) =>
  axios.put(
    domainAPI + `/api/customer/request-item/update-items-details`,
    {
      items: data.items,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Delete staging request item
const cus_stagingService_DeleteSR = (data) =>
  axios.post(
    domainAPI + `/api/staging-request/${data.id}/cancel`,
    {},
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Delete staging request item
const cus_stagingService_DeleteSRItems = (data) =>
  axios.put(
    domainAPI + '/api/customer/request-item/remove-items',
    {
      item_ids: data.item_ids,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

  // Delete staging request item
const cus_stagingService_DeleteDrawing = (data) =>
axios.put(
  domainAPI + '/api/customer/request-item/drawing',
  {
    item_id: data.item_id,
    sr_id: data.sr_id,
  },
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }
);

  // Delete staging request item
  const cus_stagingService_DeleteMaterial = (data) =>
  axios.put(
  domainAPI + '/api/customer/request-item/material',
  {
    item_id: data.item_id,
    sr_id: data.sr_id,
  },
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }
  );

  // Delete staging request item
  const cus_stagingService_corrent_DeleteMaterial = (data) =>
  axios.put(
  domainAPI + '/api/customer/request-item/current/material',
  {
    item_id: data.item_id,
    sr_id: data.sr_id,
    request_base_dir: data.request_base_dir,
  },
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }
  );

  const cus_stagingService_UpdateMaterial = (data, formatData) =>
    axios.post(domainAPI + '/api/customer/request-item/material', formatData, {
      headers: {
        Authorization: `Bearer ${data.token}`,
        'Content-Type': 'multipart/form-data',
      },
      params: {
      }
    });

  const cus_stagingService_corrent_UpdateMaterial = (data, formatData) =>
    axios.post(domainAPI + '/api/customer/request-item/current/material', formatData, {
      headers: {
        Authorization: `Bearer ${data.token}`,
        'Content-Type': 'multipart/form-data',
      },
      params: {
        request_base_dir: data.request_base_dir
      }
    });

  const cus_stagingService_successInvoicePayment = (data) =>
    axios.post(domainAPI + '/api/customer/staging-request/invoice/payment', 
    {
      staging_req_id: data.id,
      coupon_code: data.coupon_code,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });

    const cus_stagingService_successConfirmInvoicePayment = (data) =>
    axios.post(domainAPI + '/api/customer/staging-request/invoice/confirm/payment', 
    {
      staging_req_id: data.id,
      confirm_count: data.confirm_count
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });

    const cus_stagingService_setPinterest = (data) =>
    axios.post(domainAPI + `/api/customer/staging-request/${data.code}/pinterest`, 
    {
      is_pinterest: data.is_pinterest,
      prefectures: data.prefectires,
      room_type: data.room_type,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });

  // Get all coupon request
  const cus_stagingService_GetCoupon = (data) =>
  axios.get(domainAPI + '/api/coupons', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      coupon_id: data.coupon_id,
    },
  }); 

  // Get all coupon request
  const cus_stagingService_GetCanCoupon = (data) =>
  axios.get(domainAPI + `/api/coupons/${data.code}/can-use`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }); 

  const cus_stagingService_successCoupon = (data) =>
    axios.post(domainAPI + '/api/customer/staging-request/use-coupon', 
    {    
      staging_req_id: data.id, 
      is_invoice_payment: data.is_invoice_payment,
      coupon_code: data.coupon_code
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  
  // Get my design list
  const cus_stagingService_GetDesignList = (data) =>
    axios.get(domainAPI + '/api/customer/request-item/my-list', {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  
  // Update my design list
  const cus_stagingService_PostDesignDetail = (data) =>
    axios.post(domainAPI + `/api/customer/request-item/${data.id}/edit`,
    {    
      title: data.title, 
      text: data.text,
      tag: data.tag,
      prefecture: data.prefecture_id,
      house_layout: data.house_layout_id
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  
  // Send shooting request
  const cus_stagingService_SendShootingRequest = (data, formatData) =>
  axios.post(domainAPI + '/api/customer/shooting-request', formatData,
  {
    headers: {
      Authorization: `Bearer ${data.token}`
    },
  });

export {
  cus_stagingService_GetAllSR,
  cus_stagingService_GetSRData,
  cus_stagingService_GetAllSRItems,
  cus_stagingService_GetRooms,
  cus_stagingService_GetOfficeRooms,
  cus_stagingService_GetAllRooms,
  cus_stagingService_GetFunishingStyle,
  cus_stagingService_GetOfficeFunishingStyle,
  cus_stagingService_GetAllFunishingStyle,
  cus_stagingService_GetAllFurnitures,
  cus_stagingService_GetAditionalOptions,
  cus_stagingService_GetConfirmAditionalOptions,
  cus_stagingService_GetDigitalRenovationOptions,
  cus_stagingService_GetConfirmDigitalRenovationOptions,
  cus_stagingService_corrent_UpdateConfirmOptions,
  cus_stagingService_GetDeleteAditionalOptions,
  cus_stagingService_GetConfirmDeleteAditionalOptions,
  cus_stagingService_GetAllAditionalOptions,
  cus_stagingService_GetHouseLayout,
  cus_stagingService_GetAllBilling,
  cus_stagingService_CreateSR,
  cus_stagingService_CreateSRConfirmDelivery,
  cus_stagingService_CreateSRChekoutDetails,
  cus_stagingService_CreateConfirmChekoutDetails,
  cus_stagingService_CreateCR,
  cus_stagingService_UpdateSRStyleID,
  cus_stagingService_UpdateSRAddItems,
  cus_stagingService_UpdateSRUpdateItems,
  cus_stagingService_DeleteSR,
  cus_stagingService_DeleteSRItems,
  cus_stagingService_DeleteDrawing,
  cus_stagingService_DeleteMaterial,
  cus_stagingService_corrent_DeleteMaterial,
  cus_stagingService_UpdateMaterial,
  cus_stagingService_corrent_UpdateMaterial,
  cus_stagingService_successInvoicePayment,
  cus_stagingService_successConfirmInvoicePayment,
  cus_stagingService_setPinterest,
  cus_stagingService_UpdateDrawing,
  cus_stagingService_GetCoupon,
  cus_stagingService_GetCanCoupon,
  cus_stagingService_successCoupon,
  cus_stagingService_GetDesignList,
  cus_stagingService_PostDesignDetail,
  cus_stagingService_SendShootingRequest
};
