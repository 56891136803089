<template>
  <div class="main-content">
    <div ref="details-wrapper" class="printable">
      <div class="row no-print">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
              <h4 class="blue-text font-weight-bold">
                <font-awesome-icon
                  class="mr-3"
                  :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
                  @click="$router.go(-1)"
                />
                <span class="details-title">課金履歴を表示</span>
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div v-if="listingDetailsData" id="print-area">
        <div id="uploaded-image" class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-12">
                <p class="font-weight-bold details-title-wrapper">
                  <span class="details-title">領収書</span>
                </p>
              </div>

              <div class="col-12 mb-4">
                <p class="detail-issue-date-wrapper">
                  <span v-if="listingDetailsData.billing_count && listingDetailsData.billing_count > 0" class="">発行日: {{
                          formatDate(
                            listingDetailsData.billing.updated_at
                          )
                        }}
                  </span>
                  <span v-else class="">発行日: {{
                          formatDate(
                            listingDetailsData.staging_request.billing.updated_at
                          )
                        }}
                  </span>
                </p>
              </div>

              <div class="col-5">
                <p class="font-weight-bold details-name-wrapper">
                  <span class="details-name"> {{ name }}</span>
                </p>

                <p class="details-amount-wrapper mb-0">
                  <span v-if="listingDetailsData.billing_count && listingDetailsData.billing_count > 0" class="details-name">金額 <span class="font-weight-bold">{{ formatPrice(listingDetailsData.billing.amount) }} 円 </span><br>(消費税10% {{ formatPrice(getTax(listingDetailsData.billing.amount))}}円)</span>
                  <span v-else class="details-name">金額 <span class="font-weight-bold">{{ formatPrice(listingDetailsData.staging_request.billing.amount) }} 円 </span><br>(消費税10% {{ formatPrice(getTax(listingDetailsData.staging_request.billing.amount))}}円)</span>
                </p>
                <p>
                  <span><small>上記金額正に領収いたしました。</small></span>
                </p>
              </div>

              <div class="col-2 space">
              </div>

              <div class="col-5">
                <p class="detail-issue-date-wrapper">
                  <span class="">〒060-0041<br />
                    札幌市中央区大通東3丁目1－12<br />
                    クリーンリバー大通702号<br />
                    T2430001085417
                  </span>
                </p>
                <div class="company-area">
                  <span class="font-weight-bold company-name">株式会社RealtyBank</span>
                  <img class="company-seal" src="/img/company-seal.png">
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 mb-4">
                <div class="details">
                  <table width="100%">
                    <tr>
                      <th scope="col">リクエスト番号</th>
                      <th scope="col">注文数</th>
                      <th scope="col">購入日</th>
                    </tr>
                    <tr>
                      <td>{{ listingDetailsData.staging_req_id }}</td>
                      <td>{{ listingDetailsData.staging_request.billing.staging_request.request_items.length }}</td>
                      <td v-if="listingDetailsData.billing_count && listingDetailsData.billing_count > 0" class="details-name">{{ formatDate(listingDetailsData.billing.updated_at) }}</td>
                      <td v-else>{{ formatDate(listingDetailsData.staging_request.billing.updated_at) }}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="col-12 col-md-12 col-lg-12 mb-4">
                <div class="details">
                  <div class="mb-2">
                    <p class="font-weight-bold mb-4">依頼詳細</p>
                    <p>
                      <span>画像名:</span>
                      <span>
                        {{
                          listingDetailsData.filename +
                          '.' +
                          listingDetailsData.file_extension
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="mb-2">
                    <p>
                      お部屋の種類:
                      {{
                        listingDetailsData.room_area
                          ? listingDetailsData.room_area.name
                          : ''
                      }}
                    </p>
                  </div>
                  <div
                    class="mb-2"
                    v-if="listingDetailsData.furnitures.length > 0"
                  >
                    <p>
                      <span>家具:</span>
                      <span
                        v-for="(el, i) in listingDetailsData.furnitures"
                        :key="i"
                      >
                        <span v-if="el.furniture">
                          {{
                            listingDetailsData.furnitures.length !== i + 1
                              ? el.furniture.name + ','
                              : el.furniture.name
                          }}
                        </span>
                      </span>
                    </p>
                  </div>
                  <div
                    class="mb-2"
                    v-if="(listingDetailsData.billing_count && listingDetailsData.billing_count > 0) || listingDetailsData.additional_options.length > 0"
                  >
                  <p v-if="listingDetailsData.billing_count && listingDetailsData.billing_count > 0" class="m-0">
                      <span>追加オプション:</span>
                      <span
                        v-for="(el, i) in listingDetailsData.additionalOptions"
                        :key="i"
                      >
                        {{
                          listingDetailsData.additionalOptions.length !== i + 1
                            ? el.additional_option.name + ','
                            : el.additional_option.name
                        }}
                      </span>
                    </p>
                    <p v-else class="m-0">
                      <span>追加オプション:</span>
                      <span
                        v-for="(el, i) in listingDetailsData.additional_options"
                        :key="i"
                      >
                        {{
                          listingDetailsData.additional_options.length !== i + 1
                            ? el.additional_option.name + ','
                            : el.additional_option.name
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <a class="btn btn-ds light-blue white-text shadow-1" :href="downloadURL()" download>領収書ダウンロード</a>
          </div>

        </div>


        <hr
          class="mt-5"
          v-if="
            (!listingDetailsData.billing_count ||
            listingDetailsData.billing_count == 0) &&
            current_version &&
            (listingDetailsData.staging_request.status == 2 ||
              listingDetailsData.staging_request.status == 3)
          "
        />
        <div
          id="uploaded-image"
          class="row"
          v-if="
            (!listingDetailsData.billing_count ||
            listingDetailsData.billing_count == 0) &&
            current_version &&
            (listingDetailsData.staging_request.status == 2 ||
              listingDetailsData.staging_request.status == 3)
          "
        >
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-12">
                <p class="blue-text font-weight-bold">
                  <span class="details-title">ステージング画像</span>
                </p>
              </div>
              <div
                class="col-12 col-md-12 col-lg-12 col-xl-6"
                v-if="current_version"
              >
                <p class="font-weight-bold">
                  <span class="details-title">現在のバージョン</span>
                </p>
                <div class="image-wrapper pb-3">
                  <div class="image">
                    <img
                      :src="current_version.result_file_url"
                      onerror="this.src='/img/image-error.png'"
                    />
                    <a
                      href="#"
                      @click.prevent="
                        downloadItem(
                          current_version.result_file_path,
                          `SR-${listingDetailsData.id}_current_version_${current_version.delivery_date}`
                        )
                      "
                    >
                      <div class="download-wrapper float-right">
                        <font-awesome-icon
                          :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                          :style="{
                            color: '#034EA1',
                            height: '36px',
                            width: '36px',
                            top: '-60px',
                          }"
                        />
                      </div>
                    </a>
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">納品日時:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.delivery_date) }}
                      </span>
                    </p>
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">確認期限:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.deadline_date) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-md-12 col-lg-12 col-xl-6"
                v-if="old_versions.length > 0"
              >
                <p class="font-weight-bold">
                  <span class="details-title">以前のバージョン</span>
                </p>
                <div class="image-wrapper pb-5">
                  <div class="image">
                    <b-carousel
                      class="img-carousel"
                      :interval="0"
                      :controls="old_versions.length > 1 ? true : false"
                      :indicators="old_versions.length > 1 ? true : false"
                    >
                      <b-carousel-slide v-for="(el, i) in old_versions" :key="i">
                        <template #img>
                          <img
                            :src="el.result_file_url"
                            onerror="this.src='/img/image-error.png'"
                          />
                        </template>
                        <a
                          href="#"
                          @click.prevent="
                            downloadItem(
                              el.result_file_path,
                              `SR-${listingDetailsData.id}_${el.delivery_date}`
                            )
                          "
                        >
                          <div
                            class="download-wrapper float-right old-version-carousel"
                          >
                            <font-awesome-icon
                              :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                              :style="{
                                color: '#034EA1',
                                height: '36px',
                                width: '36px',
                                top: '-60px',
                              }"
                            />
                          </div>
                        </a>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">納品日時:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.delivery_date) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <pagination
          class="mt-5 mb-0"
          :data="pagination.data"
          :limit="pagination.limit"
          :show-disabled="pagination.showDisabled"
          :size="pagination.size"
          :align="pagination.align"
          @pagination-change-page="getResults"
        />
      </div>
      <div v-else>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row mt-4">
              <div class="col-12 mb-3">
                <b-skeleton width="20%"></b-skeleton>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
                <b-skeleton-img width="100%"></b-skeleton-img>
                <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
                <div class="details">
                  <div class="mb-3">
                    <b-skeleton width="80%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="50%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="100%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="60%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="90%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="40%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { globalService_DownloadIMG } from '../../services/global';
import { cus_stagingService_GetAllSRItems, cus_stagingService_GetSRData
 } from '../../services/customer/staging';

// Import Moment JS
import moment from 'moment';

//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice } from '../../common/Utility';

// Import Vuex/Store
import { mapGetters } from 'vuex';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | View',
    };
  },
  data() {
    return {
      stagingListing: null,
      billingData: null,
      listingDetailsData: null,
      old_versions: [],
      current_version: null,
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'left',
      },
    };
  },

  watch: {
    'pagination.limit'(newVal) {
      this.pagination.limit = parseInt(newVal);

      if (this.pagination.limit < 0) {
        this.pagination.limit = 0;
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentRoute']),
    name() {
      if (!this.currentUser.user_profile){
        return '';
      }

      let nameString = '';

      if (this.currentUser.user_profile.company_name) {
        nameString += this.currentUser.user_profile.company_name;
        return nameString;
      }

      if (this.currentUser.user_profile.last_name) {
        nameString += this.currentUser.user_profile.last_name;
      }

      if (this.currentUser.user_profile.first_name) {
        nameString += this.currentUser.user_profile.first_name;
      }

      nameString += ' 様';
      return nameString;
    }
  },
  mounted() {
    this.setListingDetailsData();
  },

  methods: {
    formatDate,
    formatPrice,
    setListingDetailsData() {
      let _this = this;
      _this.getResults();
    },

    getResults(page) {
      let _this = this;

      if (!page) {
        page = 1;
      }

      let stagingRequestID = _this.$route.params.id;
      let billingId = _this.$route.params.billing_id
      let accessToken = localStorage.getItem('_accessToken');

      _this.stagingListing = null;
      _this.listingDetailsData = null;

      cus_stagingService_GetSRData({
          id: stagingRequestID,
          token: accessToken,
        })
          .then((response) => {
            let data = response.data.result;
            _this.stagingListing = data;
          })
          .catch((error) => {
            console.log(error);
          });

      cus_stagingService_GetAllSRItems({
        id: stagingRequestID,
        billing_id: billingId,
        paginated: 1,
        page_size: 1,
        page: page ? page : 1,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          if (data.data.length > 0) {
            _this.listingDetailsData = data.data[0];
            _this.pagination.data = data;

            console.log(_this.listingDetailsData);
            console.log('testtest');

            //get staging versions
            const { current_version, prev_versions } = getRequestItemVersions(
              data.data[0]
            );
            _this.current_version = current_version;
            _this.old_versions = prev_versions;
          }
        })

        .catch((error) => {
          console.log(error);
        });

      _this.$store.state.currentPaginationPage = page;

      //scroll view on top
      var detailsWrapper = _this.$refs['details-wrapper'];
      if (detailsWrapper) {
        detailsWrapper.scrollIntoView({ block: 'center', inline: 'nearest' });
      }
    },

    downloadItem(path, filename) {
      let accessToken = localStorage.getItem('_accessToken');

      globalService_DownloadIMG({
        token: accessToken,
        path: path,
      }).then((response) => {
        let url = window.URL.createObjectURL(response.data);
        let link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);

        link.click();
      });
    },
    downloadURL(){
      const domainAPI = this.$store.state.domainAPI;
      return domainAPI + `/api/billing/${this.listingDetailsData.id}/receipt/download`
    },
    getTax(amount){
      const tax = (amount * 0.1 ) / 1.1
      return tax;
    }
  },
};
</script>

<style scoped>
.main-content {
  padding: 110px 60px 150px 350px;
}

@media print {
  .no-print {
    display: none;
  }
  .printable {
    width: 100%;
  }
}

.details-title-wrapper {
  text-align: center;
  border-bottom: #000000 solid 3px;
}

.detail-issue-date-wrapper {
  text-align: left;
}

.details-name-wrapper {
  text-align: center;
  border-bottom: #000000 solid 2px;
}

.details-amount-wrapper {
  padding: 10px;
  text-align: center;
  border: #000000 solid 3px;
}

.details-title, .details-name {
  font-size: 19px;
  font-style: normal normal medium 19px/23px Roboto;
}
.details-title, .details-name {
  margin-bottom: 43px;
}

.details {
  padding: 10px;
  border: #000000 solid 1px;
}

.details span,
.details p {
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}

#uploaded-image {
  margin-top: 40px;
}

#uploaded-image .image {
  height: 340px;
  background: #ececec;
  border-radius: 15px;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1440px) {
  #uploaded-image .image {
    height: 300px;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .image {
    height: 400px;
  }
}

@media only screen and (max-width: 425px) {
  #uploaded-image .image {
    height: 250px;
  }
  .company-name {
    margin-top: none;
  }
}

#uploaded-image .image img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
}

#uploaded-image .image .img-carousel {
  height: 100%;
  border-radius: 15px;
  background: #e9e9e9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#uploaded-image .timestamp {
  margin: 10px 0 0 20px;
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .timestamp {
    margin: 10px 0 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .details {
    margin-top: 60px;
  }
}

@media screen and (min-width: 420px) {
  .company-name {
    margin-top: 40px;
  }
}

@media screen and (min-width: 330px) {
  .space {
    margin-right: -30px;
  }
}

.download-wrapper {
  position: absolute;
  right: 36px;
  bottom: 12px;
  background: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.download-wrapper.old-version-carousel {
  right: -40px;
  bottom: -8px;
}

.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
}

.company-seal {
  max-width:65px;
  max-height: 65px;
  margin-left: 5px;
}
.company-area {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
</style>
