var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"blue-text title"},[_vm._v("課金履歴")]),(_vm.billingHistoryTableData)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"},[(_vm.billingHistoryTableData.data.length > 0)?_c('div',[_c('table',{staticClass:"table shadow-1",attrs:{"striped":"","hover":""}},[_vm._m(0),(_vm.billingHistoryTableData.data.length === 0)?_c('tbody',_vm._l((15),function(el,i){return _c('tr',{key:i},[_c('td',[_c('b-skeleton')],1),_c('td',[_c('b-skeleton')],1),_c('td',[_c('b-skeleton')],1),_c('td',[_c('b-skeleton')],1),_c('td',[_c('b-skeleton')],1)])}),0):_c('tbody',_vm._l((_vm.billingHistoryTableData.data),function(el,i){return _c('tr',{key:i},[_c('td',{staticClass:"align-middle text-center",attrs:{"data-label":"リクエスト番号"}},[_vm._v(" "+_vm._s(el.staging_req_id)+" ")]),_c('td',{staticClass:"align-middle text-center",attrs:{"data-label":"注文数"}},[_vm._v(" "+_vm._s(el.staging_request.request_items.length)+" ")]),_c('td',{staticClass:"align-middle text-center",attrs:{"data-label":"購入日"}},[_vm._v(" "+_vm._s(_vm.formatDate(el.created_at))+" ")]),_c('td',{staticClass:"align-middle text-center",attrs:{"data-label":"ご請求額"}},[_vm._v(" "+_vm._s(_vm.formatPrice(el.amount))+" ")]),_c('td',{staticClass:"align-middle text-center",attrs:{"data-label":"領収書"}},[_c('router-link',{attrs:{"to":{
                      name: 'userRoute-billing-receipt',
                      params: {
                        id: el.staging_req_id,
                        billing_id: el.id,
                      },
                    }}},[(el.is_invoice_payment != '1')?_c('button',{staticClass:"btn btn-ds light-blue white-text shadow-1 mt-2 mb-2"},[_vm._v(" 領収書を発行 ")]):_vm._e()])],1),_c('td',{staticClass:"align-middle text-center",attrs:{"data-label":"詳細"}},[_c('router-link',{attrs:{"to":{
                      name: 'userRoute-staging-order-details',
                      params: {
                        id: el.staging_req_id,
                      },
                    }}},[_c('button',{staticClass:"btn btn-ds light-blue white-text shadow-1 mt-2 mb-2"},[_vm._v(" 詳細を表示 ")])])],1)])}),0)]),_c('pagination',{staticClass:"mt-3 mb-0",attrs:{"data":_vm.pagination.data,"limit":_vm.pagination.limit,"show-disabled":_vm.pagination.showDisabled,"size":_vm.pagination.size,"align":_vm.pagination.align},on:{"pagination-change-page":_vm.paginateData}})],1):_c('div',[_c('b-card',{staticClass:"shadow-1 pt-5 pb-5 text-center",attrs:{"no-header":"","no-footer":""}},[_c('img',{attrs:{"src":"/img/icons/ico-info.svg","alt":"ico-info"}}),_c('h3',{staticClass:"m-0 mt-3 grey-text non-content-text"},[_vm._v("表示できる内容がありません。")])])],1)])]):_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"},[_c('table',{staticClass:"table shadow-1",attrs:{"striped":"","hover":""}},[_vm._m(1),_c('tbody',_vm._l((15),function(el,i){return _c('tr',{key:i},[_c('td',[_c('b-skeleton')],1),_c('td',[_c('b-skeleton')],1),_c('td',[_c('b-skeleton')],1),_c('td',[_c('b-skeleton')],1),_c('td',[_c('b-skeleton')],1)])}),0)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("リクエスト番号")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("注文数")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("購入日")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("ご請求額")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("領収書")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("詳細")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("リクエスト番号")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("注文数")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("購入日")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("ご請求額")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("詳細")])])])}]

export { render, staticRenderFns }