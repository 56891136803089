<template>
  <div class="main-content">
    <div class="blue-text title">納品済み一覧</div>
    <div id="delivered-list" class="mt-4" v-if="deliveredTableData">
      <div v-if="deliveredTableData.data.length > 0">
        <div
          class="row pt-3 item"
          v-for="(el, i) in deliveredTableData.data"
          :key="i"
        >
          <div class="col-12 col-md-12 col-lg-12 col-xl-7 details-wrapper">
            <div class="row details" style="text-align: center">
              <div class="col-12 col-md-12 col-lg-12 col-xl-8">
                <div class="row">
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">注文 ID</p>
                    <p>{{ el.reference_no }}</p>
                  </div>
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">スタイル</p>
                    <p v-if="el.style">
                      {{ el.style.name }}
                    </p>
                    <p v-else>
                    </p>
                  </div>
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">枚数</p>
                    <p>
                      {{
                        el.request_items.length === 1
                          ? `${el.request_items.length} 枚`
                          : `${el.request_items.length} 枚`
                      }}
                    </p>
                  </div>
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">納品日</p>
                    <p>
                      {{ formatDate(el.delivery_date) }}
                    </p>
                  </div>
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">確認期限</p>
                    <p>
                      {{ formatDate(el.deadline_date) }}
                    </p>
                  </div>
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">合計購入金額</p>
                    <p>
                      {{ el.billing ? formatPrice(el.all_billing_amount) : 'N/A' }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-4">
                <b-carousel
                  class="img-carousel"
                  :interval="0"
                  :controls="el.request_items.length > 1 ? true : false"
                  :indicators="el.request_items.length > 1 ? true : false"
                >
                  <b-carousel-slide
                    v-for="(_el, _i) in el.request_items"
                    :key="_i"
                  >
                    <template #img>
                      <img
                        :src="getCurrentImageVersionUrl(_el)"
                        onerror="this.src='/img/image-error.png'"
                        @click="el.request_category == 2 ? openImage(getCurrentImageVersionUrl(_el)) : openModal(getCurrentImageVersionUrl(_el))"
                      />
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12 col-xl-5 action-wrapper">
            <div class="row actions" style="text-align: center">
              <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <button
                  class="btn btn-ds blue white-text"
                  v-waves.light
                  v-on:click="setConfirmDeliveryModal(el.id)"
                >
                  納品確認を行う
                </button>
              </div>
              <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <router-link
                  :to="{
                    name: 'userRoute-staging-delivered-view',
                    params: {
                      id: el.id,
                    },
                  }"
                >
                  <button class="btn btn-ds blue white-text" v-waves.light>
                    詳細を表示
                  </button>
                </router-link>
              </div>
              <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <router-link
                  :to="{
                    name: 'userRoute-staging-delivered-correction-view',
                    params: {
                      id: el.id,
                    },
                  }"
                >
                  <button class="btn btn-ds blue white-text" v-waves.light>
                    修正リクエスト
                  </button>
                </router-link>
              </div>
              <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <button
                  class="btn btn-ds blue white-text"
                  v-waves.light
                  v-on:click="downloadImages(el.request_items)"
                >
                  画像をダウンロード
                </button>
              </div>
            </div>
          </div>
        </div>
        <pagination
          class="mt-3 mb-0"
          :data="deliveredTableData"
          :limit="pagination.limit"
          :show-disabled="pagination.showDisabled"
          :size="pagination.size"
          :align="pagination.align"
          @pagination-change-page="paginateData"
        />
      </div>
      <div v-else>
        <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
          <img src="/img/icons/ico-info.svg" alt="ico-info" />
          <h3 class="m-0 mt-3 grey-text non-content-text">表示できる内容がありません。</h3>
        </b-card>
      </div>
    </div>
    <div class="mt-5" v-else>
      <div class="row m-0 pt-3 pb-3 item" v-for="(el, i) in 10" :key="i">
        <div class="col-12 col-md-12 col-lg-12 col-xl-7 details-wrapper">
          <div class="row details">
            <div class="col-12 col-md-12 col-lg-12 col-xl-8">
              <div class="row">
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="50%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="50%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-4">
              <b-skeleton-img width="100%"></b-skeleton-img>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-5 action-wrapper">
          <div class="row actions">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
              <b-skeleton type="button" height="56px" width="100%"></b-skeleton>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
              <b-skeleton type="button" height="56px" width="100%"></b-skeleton>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
              <b-skeleton type="button" height="56px" width="100%"></b-skeleton>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
              <b-skeleton type="button" height="56px" width="100%"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="confirm-delivery-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('confirm-delivery-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          編集された写真を承認しますか？
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds light-grey white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('confirm-delivery-modal')"
            >
              <span>キャンセル</span>
            </button>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="confirmDelivery"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <image-modal @closeModal="closeModal()" v-if="isOpenModal">
      <div class="bl_image">
        <img :src="modalImageUrl">
      </div>
    </image-modal>
  </div>
</template>

<script>
// Import Services
import {
  cus_stagingService_GetAllSR,
  cus_stagingService_CreateSRConfirmDelivery,
} from '../../services/customer/staging';
import { globalService_DownloadIMG } from '../../services/global';

// Import Moment JS
import moment from 'moment';

// Import JS Zip
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';

//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice } from '../../common/Utility';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | Delivered',
    };
  },

  data() {
    return {
      deliveredTableData: null,
      pagination: {
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'center',
      },
      itemToBeConfirm: null,
      isMix:false,
      isOpenModal: false,
      modalImageUrl: null,
    };
  },

  watch: {
    'pagination.limit'(newVal) {
      this.pagination.limit = parseInt(newVal);

      if (this.pagination.limit < 0) {
        this.pagination.limit = 0;
      }
    },
    $route() {
      this.getResults(this.getUrlQueryParamsPage());
    },
  },

  components: {
    'image-modal': ImageModal,
  },

  mounted() {
    this.getResults(this.getUrlQueryParamsPage());
  },

  methods: {
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    closeModal(){
      this.isOpenModal = false;
    },
    openImage(imgUrl){
      this.$router.push({
        name: 'anonymousRoute-image',
        query: { image: imgUrl },
      });
    },
    formatDate,
    formatPrice,
    getUrlQueryParamsPage() {
      let currentRouteURLPrams = new URLSearchParams(location.search);
      return currentRouteURLPrams.get('page');
    },
    getResults(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      cus_stagingService_GetAllSR({ 
        page: page ? page : 1, 
        status: 2, 
        token: accessToken 
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.deliveredTableData = data;
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            'データの取得中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );
      });
    },
    paginateData(page) {
      let _this = this;
      let currentRouteURLPrams = new URLSearchParams(location.search);
      let url_page = currentRouteURLPrams.get('page');
      if (url_page != page) {
        _this.$router.push({
          name: 'userRoute-staging-delivered',
          query: { page: page },
        });
      }
    },
    setConfirmDeliveryModal(id) {
      this.itemToBeConfirm = id;
      this.$bvModal.show('confirm-delivery-modal');
    },

    confirmDelivery() {
      let _this = this;

      _this.$bvModal.hide('confirm-delivery-modal');

      _this.$store.state.modalLoaderMessage = '確認中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      cus_stagingService_CreateSRConfirmDelivery({
        id: _this.itemToBeConfirm,
        token: accessToken,
      })
        .then((response) => {
          console.log(response);

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
            _this.$router.push({
              name: 'userRoute-staging-confirmed',
            });
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            '依頼の確認中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },

    urlToPromise(url) {
      return new Promise(function (resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    },
    downloadImages(requestItems) {
      let link = document.createElement('a');
      document.body.appendChild(link);

      let accessToken = localStorage.getItem('_accessToken');

      //download current confirmed image for each request items
      requestItems.forEach((ri) => {
        const { current_version } = getRequestItemVersions(ri);
        globalService_DownloadIMG({
          token: accessToken,
          path: current_version.result_file_path,
          isMix:this.isMix,
        }).then((response) => {
          let url = window.URL.createObjectURL(response.data);
          link.href = url;
          link.setAttribute(
            'download',
            `SR-${ri.id}_current_version_${current_version.delivery_date}`
          );
          link.click();
        });
      });
    },
    getCurrentImageVersionUrl(requestItem) {
      const { current_version } = getRequestItemVersions(requestItem);
      return current_version ? current_version.result_file_url : '';
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
#delivered-list .item {
  margin-bottom: 20px;
  border-radius: 20px;
  background: #f5f5f5;
}

@media only screen and (max-width: 1199px) {
  #delivered-list .item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 1199px) {
  #delivered-list .item .details {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
}

#delivered-list .item .details .img-carousel {
  height: 130px;
  background: #e9e9e9;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
@media only screen and (max-width: 1500px) {
  #delivered-list .item .details .img-carousel {
    height: 130px;
  }
  #delivered-list .item .details p {
    font-size: 12px;
    word-break: normal;
    word-wrap: normal;
  }
  #delivered-list .item .actions button {
    width: 150px;
    height: 50px;
    font-size: 10px;
    padding: 13px 0.1rem 13px 0.1rem;
    white-space: initial;
    text-overflow: initial;
    text-align: center;
  }
  #delivered-list .item .action-wrapper .actions div {
    padding: 2px;
  }
  #delivered-list .item .action-wrapper {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
    padding-right: 20px;
  }

  #delivered-list .item .details-wrapper {
    flex: 0 0 72.444444%;
    max-width: 72.444444%;
  }
}
@media only screen and (max-width: 1199px) {
  #delivered-list .item .details .img-carousel {
    height: 350px;
  }
  #delivered-list .item .action-wrapper {
    flex: initial;
    max-width: initial;
    padding-right: initial;
  }

  #delivered-list .item .details-wrapper {
    flex: initial;
    max-width: initial;
  }
}

@media only screen and (max-width: 767px) {
  #delivered-list .item .details .img-carousel {
    height: 250px;
  }
  #delivered-list {
    padding: 20px;
  }
}

.title {
  font-size: 20px;
}

.non-content-text {
  font-size: 19px;
}

.bl_image{
  display: flex;
  justify-content: center;
}
</style>
