<template>
  <div class="main-content">
    <h4 class="blue-text font-weight-bold">
      <router-link :to="{ name: 'userRoute-staging-delivered' }">
        <font-awesome-icon
          class="mr-3"
          :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
        />
      </router-link>
      <span>修正依頼</span>
    </h4>
    <div class="col-12" v-if="listingDetailsData">
      <div v-if="summary === false">
        <div id="uploaded-image" class="row" v-if="current_version">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-12">
                <p class="blue-text font-weight-bold">
                  <span class="details-title">ステージング画像</span>
                  <span class="float-right">
                    {{ pagination.data.current_page }}/{{
                      pagination.data.last_page
                    }}
                    枚
                  </span>
                </p>
              </div>
              <div
                class="col-12 col-md-12 col-lg-12 col-xl-6"
                v-if="current_version"
              >
                <p class="font-weight-bold">
                  <span class="details-title">現在のバージョン</span>
                </p>
                <div class="image-wrapper pb-3">
                  <div class="image">
                    <img
                      :src="current_version.result_file_url"
                      onerror="this.src='/img/image-error.png'"
                      @click="openModal(current_version.result_file_url)"
                    />
                    <a
                      href="#"
                      @click.prevent="
                        downloadItem(
                          current_version.result_file_path,
                          `SR-${listingDetailsData.id}_current_version_${current_version.delivery_date}`
                        )
                      "
                    >
                      <div class="download-wrapper float-right">
                        <font-awesome-icon
                          :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                          :style="{
                            color: '#034EA1',
                            height: '36px',
                            width: '36px',
                            top: '-60px',
                          }"
                        />
                      </div>
                    </a>
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">納品日時:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.delivery_date) }}
                      </span>
                    </p>
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">確認期限:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.deadline_date) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-md-12 col-lg-12 col-xl-6"
                v-if="old_versions.length > 0"
              >
                <p class="font-weight-bold">
                  <span class="details-title">以前のバージョン</span>
                </p>
                <div class="image-wrapper pb-5">
                  <div class="image">
                    <b-carousel
                      class="img-carousel"
                      :interval="0"
                      :controls="old_versions.length > 1 ? true : false"
                      :indicators="old_versions.length > 1 ? true : false"
                      @sliding-start="onSlideStart"
                    >
                      <b-carousel-slide
                        v-for="(el, i) in old_versions"
                        :key="i"
                      >
                        <template #img>
                          <img
                            :src="el.result_file_url"
                            onerror="this.src='/img/image-error.png'"
                            :id="'carousel-img-' + i"
                            :data-delivery-date="formatDate(el.delivery_date)"
                            @click="openModal(el.result_file_url)"
                          />
                        </template>
                        <a
                          href="#"
                          @click.prevent="
                            downloadItem(
                              el.result_file_path,
                              `SR-${listingDetailsData.id}_${el.delivery_date}`
                            )
                          "
                        >
                          <div
                            class="download-wrapper float-right old-version-carousel"
                          >
                            <font-awesome-icon
                              :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                              :style="{
                                color: '#034EA1',
                                height: '36px',
                                width: '36px',
                                top: '-60px',
                              }"
                            />
                          </div>
                        </a>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">納品日時:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.delivery_date) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="comments" class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <p class="blue-text font-weight-bold">修正依頼コメント</p>
            <b-form-textarea
              v-model="
                comments.find(
                  (el) => el.request_item_id === listingDetailsData.id
                ).comment
              "
              placeholder="以下の特殊な記号は使えません。 !@#$%^&*()[]"
              rows="10"
              max-rows="10"
            ></b-form-textarea>
            <p class="blue-text">修正不要の場合はコメントの入力は必要ありません</p>

            <p class="m-0">
              <span class="mr-5 font-weight-bold dark-blue-text">
                追加オプション
              </span>
              <b-spinner
                class="grey-text"
                small
                v-if="all_additionalOptions.loader"
              ></b-spinner>
            </p>
            <div class="mt-1" v-if="all_additionalOptions.loader">
              <b-skeleton class="mb-2" animation="wave"></b-skeleton>
              <b-skeleton class="mb-2" animation="wave"></b-skeleton>
              <b-skeleton class="mb-2" animation="wave"></b-skeleton>
              <b-skeleton class="mb-2" animation="wave"></b-skeleton>
            </div>
            <b-form-group v-else>
              <b-form-checkbox-group
                v-model="additionalOptions[pagination.data.current_page - 1].value"
                v-bind:changed="optionChange(pagination.data.current_page - 1, additionalOptions[pagination.data.current_page - 1], materialCount[listingDetailsData.id], listingDetailsData.id)"
                name="additional-options"
              >
                <div
                  class="item"
                  style="font-size:13px;"
                  v-for="(el, i) in all_additionalOptions.options"
                  :key="i"
                >
                  <b-form-checkbox
                    class="grey-text step3"
                    :value="el.value"
                    :disabled="!el.can_select"
                  >
                    {{ el.text }} - {{ formatPrice(el.price) }}
                  </b-form-checkbox>
                </div>
              </b-form-checkbox-group>
            </b-form-group>

            <!-- 素材S -->
            <div v-if="materialDisable[pagination.data.current_page - 1]">
              <div class="d-flex" style="flex-wrap: wrap;">
                <input
                  :id="'material-uploader_'+ listingDetailsData.id"
                  type="file"
                  class="form-control"
                  accept=".jpeg,.jpg,.png,.pdf"
                  v-on:change="validateMaterialPdfs(listingDetailsData.id, $event)"
                  multiple
                  hidden
                />

                <div class="img-upload-box">
                    <button
                      class="btn btn-dt dark-blue-outline dark-blue-text"
                      style="margin: 10px 10px 10px -1px;font-size: 11px"
                      v-waves.light
                      v-on:click="setMaterialAddPdf(materialCount[listingDetailsData.id], listingDetailsData.id)"
                      :disabled="
                        materialCount[listingDetailsData.id] > 10 ? true : false
                      "
                    >

                      <div class="drawing-area" v-if="(materialUploadCount > 0 || materialLoaderItems.length > 0) && materialLoadingItemId == listingDetailsData.id">
                        <div>
                          <b-spinner style="width: 0.75rem; height: 0.75rem;"></b-spinner>
                          <span style="margin-left: 10px;margin-right:10px;" class="img-btn">アップロード中 ({{ materialUploadCount }})</span>
                        </div>
                      </div>
                      <div v-else class="drawing-area" style="width: fit-content;">
                        <font-awesome-icon class="icon" :icon="{ prefix: 'fas', iconName: 'file-arrow-up' }"/>
                        <span v-if="materialCount[listingDetailsData.id] == 0" style="margin-left: 10px;" class="img-btn">サンプルの壁紙・床材をアップロード</span>
                        <span v-else class="img-btn">{{ materialCount[listingDetailsData.id] }}件アップロード済み</span>
                      </div>
                      
                    </button>
                </div>
                <div>
                    <button
                      class="btn btn-dt dark-blue-text"
                      style="width: 120px; margin: 3px 0px 10px -10px;"
                      v-waves.light
                      :disabled="
                        materialCount[listingDetailsData.id] > 0 ? false : true
                      "
                      v-on:click="delMaterialPdf(materialCount[listingDetailsData.id], listingDetailsData.id)"
                    >
                      <div class="drawing-area" style="background:#055ab8; color:#fff; border-radius: 6px; padding: 7px;font-size: 11px">
                        <span class="img-btn">図面削除</span>
                      </div>
                    </button>
                </div>
            <!-- 素材E -->

              </div>
            </div>

            <p class="warning-text">
              ●修正コメントに関しての注意事項<br>
              良い例：木目調の家具にしてください、ソファーの色を暗めの茶色にしてください<br>
              悪い例：〇〇がターゲットのインテリア、〇〇に好かれるような家具、〇〇のような暮らしのイメージの家具や色<br>
              ※具体的な家具や配置のイメージがある場合は、お手数ですが配置図や写真データなどを別途当社までメールをお願いいたします<br>
              info@digitalstaging.jp<br>
              参考となる家具などのURLを修正コメントに入れていただくことも可能です<br><br>
            </p>
            <div class="actions">
              <button
                class="btn btn-ds mt-3 light-grey white-text"
                v-waves.light
                v-on:click="goToPrevious(pagination.data.from)"
                v-if="pagination.data.from !== pagination.currentPage"
              >
                <font-awesome-icon
                  :icon="{ prefix: 'fas', iconName: 'chevron-left' }"
                />
                <span class="ml-3">もどる</span>
              </button>
              <button
                class="btn btn-ds mt-3 dark-blue white-text"
                v-waves.light
                v-on:click="goToNext(pagination.data.from)"
                v-if="pagination.data.from !== pagination.data.last_page"
              >
                <span class="mr-3">つぎへ</span>
                <font-awesome-icon
                  :icon="{ prefix: 'fas', iconName: 'chevron-right' }"
                />
              </button>
              <button
                class="btn btn-ds mt-3 dark-blue white-text"
                v-waves.light
                v-on:click="setSummary"
                v-else
              >
                <span class="mr-3">OK</span>
                <font-awesome-icon
                  :icon="{ prefix: 'fas', iconName: 'chevron-right' }"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h4 class="mt-3 blue-text font-weight-bold">確認</h4>
        <div v-if="summaryEmptyState === false">
          <div v-for="(el, i) in comments" :key="i">
            <div class="row summary-item" v-if="el.comment || resOptions[optionKey(el.request_item_id)]">
              <div class="col-12 col-md-5 col-lg-4 col-xl-4 image">
                <img
                  :src="el.image"
                  onerror="this.src='/img/image-error.png'"
                  @click="openModal(el.image)"
                />
              </div>
              <div class="col-12 col-md-7 col-lg-8 col-xl-8 details">
                <h5 class="font-weight-bold">修正依頼コメント:</h5>
                <pre><p class="mt-3">{{ el.comment ? el.comment : 'No Comment' }}</p></pre>
                <h5 class="font-weight-bold">追加オプション:</h5>
                <pre><p class="mt-3" v-for="(op, j) in resOptions[optionKey(el.request_item_id)]" :key="j">{{ op.text }}</p></pre>
              </div>
            </div>
          </div>
          <div 
            class="row summary-item"
            style="padding: 5px 10px 5px 5px; justify-content: flex-end"
            >
            合計金額：{{ formatPrice(sumPrice) }}
          </div>
          <b-alert
            class="m-0 mt-3 light-grey-outline grey-text"
            variant="warning"
            show
            v-if="formMessage !== null"
          >
            {{ formMessage }}
          </b-alert>
        </div>
        <div v-else>
          <b-alert
            class="m-0 mt-3 light-grey-outline grey-text"
            variant="warning"
            show
          >
            コメントを記入してください。
          </b-alert>
        </div>

        <div class="actions">
          <button
            class="btn btn-ds mt-3 light-grey white-text"
            v-waves.light
            v-on:click="goToPrevious(pagination.data.from + 1)"
          >
            <font-awesome-icon
              :icon="{ prefix: 'fas', iconName: 'chevron-left' }"
            />
            <span class="ml-3">もどる</span>
          </button>
          <div
            v-if="summaryEmptyState === false"
            class="btn btn-ds mt-3 dark-blue white-text"
            v-on:click="dispAlertModalState"
            v-waves.light
          >
            依頼に進む
        </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row mt-4">
            <div class="col-12 mb-3">
              <b-skeleton width="20%"></b-skeleton>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
              <b-skeleton-img width="100%"></b-skeleton-img>
              <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
              <b-skeleton-img width="100%"></b-skeleton-img>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <b-skeleton class="mb-3" width="20%"></b-skeleton>
          <b-skeleton-img height="30vh" width="100%"></b-skeleton-img>
        </div>
      </div>
    </div>
    <image-modal @closeModal="closeModal()" v-if="isOpenModal">
      <img :src="modalImageUrl">
    </image-modal>

    <b-modal
      id="confirm-modal"
      class="border-radius-2"
      ref="confirmModal"
      size="lg"
      hide-footer
      hide-header
      no-close-on-esc
      centered
    >
      <h5
          class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
          @click="$refs.confirmModal.hide()"
      >
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
          <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
          <p class="mt-4 mb-0 dark-blue-text font-weight-bold text-size">
          以下をご確認ください。
          </p>
          <div class="container text-left" style="margin:10px;">
            <b-form-checkbox
                class="d-inline-block mr-2 mt-4 mb-0 dark-blue-text font-weight-bold text-size"
                v-model="isPayment"
                v-on:change="isAllChecked"
            >
                <p style="text-align: left">
                  追加オプションとなる作業をご希望の方は、追加オプション選択済ですか？<br>（請求書払いの方は後日合わせて請求のためこちらで許諾確認となります）
                </p>
            </b-form-checkbox>

            <b-form-checkbox
                class="d-inline-block mr-2 mt-4 mb-0 dark-blue-text font-weight-bold text-size"
                style="float: left"
                v-model="isUpdate"
                v-on:change="isAllChecked"
            >
                <p style="text-align: left">
                  具体的な修正指示をしましたか（机を丸形の木目調に変更etc.）
                </p>
            </b-form-checkbox>

            <b-form-checkbox
                class="d-inline-block mr-2 mt-4 mb-0 dark-blue-text font-weight-bold text-size"
                v-model="isPhoto"
                v-on:change="isAllChecked"
                
            >
                <p style="text-align: left">
                  写真の差し替え、依頼時に選択したインテリアスタイルの変更ではないですか？<br>(作業後の変更不可)
                </p>
            </b-form-checkbox>
          </div>
          <div class="row mb-3 mt-1">
            <div class="col-12 mb-2">
              <button
                :class="checkedAll
                ? 'actionsbtn btn-ds dark-blue white-text pt-2 pb-2'
                : 'actionsbtn btn-ds grey white-text pt-2 pb-2'
                "
                type="button"
                v-waves.light
                v-on:click="checkAlertModalState"
                style="width: 455px; height: 50px; max-width: 100%"
              >
                OK
              </button>
            </div>
          </div>
      </div>
    </b-modal>
    <b-modal
      id="payment-information-modal"
      class="border-radius-2"
      size="lg"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
      v-if="sumPrice !== 0"
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="destroyStripeElements"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-payment.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">お支払い情報</h5>
        <div class="row mt-4">
          <div class="col-2 p-1">
            <img
              src="/img/icons/ico-card-mastercard.svg"
              alt="ico-card-mastercard"
            />
          </div>
          <div class="col-2 p-1">
            <img src="/img/icons/ico-card-visa.svg" alt="ico-card-visa" />
          </div>
          <div class="col-2 p-1">
            <img
              src="/img/icons/ico-card-dinners-club.svg"
              alt="ico-card-dinners-club"
            />
          </div>
          <div class="col-2 p-1">
            <img src="/img/icons/ico-card-paypal.svg" alt="ico-card-paypal" />
          </div>
          <div class="col-2 p-1">
            <img src="/img/icons/ico-card-jcb.svg" alt="ico-card-jcb" />
          </div>
          <div class="col-2 p-1">
            <img
              src="/img/icons/ico-card-american-express.svg"
              alt="ico-card-american-express"
            />
          </div>
        </div>
        <div class="row pl-sm-5-5 pr-sm-5-5 pb-3 text-left">
          <div class="col-12">
            <div class="mt-4">
              <p class="m-0 font-weight-bold">クレジットカード番号</p>
              <div id="card-number" class="form-control"></div>
            </div>
          </div>
          <div class="col-8">
            <div class="mt-4">
              <p class="m-0 font-weight-bold">有効期限</p>
              <div id="card-expiry" class="form-control"></div>
            </div>
          </div>
          <div class="col-4">
            <div class="mt-4">
              <p class="m-0 font-weight-bold">CVC</p>
              <div id="card-cvc" class="form-control"></div>
            </div>
          </div>
          <div class="col-12">
            <div class="mt-4">
              <p class="m-0 font-weight-bold">カード名義</p>
              <input
                id="email"
                class="form-control shadow-1"
                type="email"
                minlength="2"
                maxlength="100"
                v-model="cardName"
                v-on:keyup="formMessage = null"
                placeholder="カード名を入力してください"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div id="card-error"></div>
            <b-alert
              class="m-0 mt-3 light-grey-outline grey-text"
              variant="warning"
              show
              v-if="formMessage !== null"
            >
              {{ formMessage }}
            </b-alert>
          </div>
          <div class="col-12 mt-5">
            <button
              class="btn btn-ds dark-blue white-text"
              type="button"
              v-waves.light
              v-on:click="finalizeStaging"
            >
              クレジットカードで支払う
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="payment-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="container text-center">
        <div v-if="isCardError">
          <img
            class="mt-4"
            src="/img/icons/ico-payment-error.svg"
            alt="ico-info"
          />
          <h5 class="mt-4 dark-blue-text font-weight-bold">
            お支払いが失敗しました
          </h5>
          <p class="grey-text">お支払いが失敗し、注文が完了しませんでした</p>
          <div class="row mb-3 mt-5">
            <div class="col-12">
              <button
                class="btn btn-ds dark-blue white-text pt-2 pb-2"
                type="button"
                v-waves.light
                v-on:click="hidePaymentSuccessModal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <img
            class="mt-4"
            src="/img/icons/ico-payment-success.svg"
            alt="ico-info"
          />
          <h5 class="mt-4 dark-blue-text font-weight-bold">
            お支払いが完了しました
          </h5>
          <p class="grey-text">お支払い及び注文が正常に完了しました</p>
          <div class="row mb-3 mt-5">
            <div class="col-12">
              <router-link :to="{ name: 'userRoute-billing-history' }">
                <button
                  class="btn btn-ds dark-blue white-text pt-2 pb-2"
                  type="button"
                  v-waves.light
                >
                  OK
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="invoice-success-modal"
      class="border-radius-2"
      size="lg"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
      v-if="sumPrice !== 0"
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="destroyInvoiceElements"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <div>
          <h5 class="mt-4 dark-blue-text font-weight-bold">
            このまま依頼を進めてもよろしいでしょうか？<br>
            翌月に請求書にて費用が請求されます。
          </h5>
          <p class="grey-text"></p>
          <div class="row mb-3 mt-5">
            <div class="col-12">
              <button
                class="btn btn-ds dark-blue white-text pt-2 pb-2"
                type="button"
                v-waves.light
                v-on:click="invoiceSuccessModal"
              >
                依頼する
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Services
import {
  cus_stagingService_GetAllSRItems,
  cus_stagingService_GetSRData,
  cus_stagingService_CreateCR,
  cus_stagingService_GetConfirmAditionalOptions,
  cus_stagingService_GetConfirmDeleteAditionalOptions,
  cus_stagingService_GetConfirmDigitalRenovationOptions,
  cus_stagingService_corrent_DeleteMaterial,
  cus_stagingService_corrent_UpdateMaterial,
  cus_stagingService_corrent_UpdateConfirmOptions,
  cus_stagingService_successConfirmInvoicePayment,
  cus_stagingService_CreateConfirmChekoutDetails,
} from '../../services/customer/staging';
import { globalService_DownloadIMG } from '../../services/global';

// Import Moment JS
import moment from 'moment';

// Import Stripe Element
import Vue from 'vue';
import { StripePlugin } from '@vue-stripe/vue-stripe';

//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice } from '../../common/Utility';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

const options = {
  pk: process.env.VUE_APP_Stripe_Key,
};

Vue.use(StripePlugin, options);

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | Delivered - View',
    };
  },

  data() {
    return {
      additionalOptions: [],
      all_additionalOptions: {
          options: [],
          loader: true,
      },

      materialLoadingItemId: null,
      materialLoaderItems: [],
      materialUploadCount: 0,
      materialNumber: [],
      material: [],
      materialFileName: [],
      materialCount: [],
      materialDisable: [],
      stagingListing: null,
      listingDetailsData: null,
      old_versions: [],
      current_version: null,
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'center',
        currentPage: this.$store.state.currentPaginationPage,
      },
      comments: [],
      summary: false,
      summaryEmptyState: null,
      formMessage: null,
      delivery_date: '',
      isMix:false,
      isOpenModal: false,
      modalImageUrl: null,
      lastPage: 0,
      resOptions: [],
      sumPrice: 0,
      isPayment: false,
      isUpdate: false,
      isPhoto: false,
      checkedAll: false,
      isInvoice: false,
      confirmCount: 0,
      isInvoicePayment: 0,
      isCardError: false,
      summaryData: null,
      cardNumber: null,
      cardExpiry: null,
      cardCVC: null,
      cardName: null,
      requestBaseDir: null,
    };
  },

  components: {
    'image-modal': ImageModal,
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
  },

  created() {
    this.$store.state.currentPaginationPage = 1;
    this.setListingDetailsData();
  },
  mounted() {
  },

  updated() {
    if (this.delivery_date == '') {
      this.setDeliveryDate(0); // set delivery date of 1st image in carousel
    }
  },

  methods: {
    formatPrice,
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    isAllChecked() {
      if (this.isPhoto && this.isUpdate && this.isPayment) {
        this.checkedAll = true;
      } else {
        this.checkedAll = false
      }
      console.log(this.checkedAll);
    },
    closeModal(){
      this.isOpenModal = false;
    },
    formatDate,
    downloadItem(path, filename) {
      let accessToken = localStorage.getItem('_accessToken');

      globalService_DownloadIMG({
        token: accessToken,
        path: path,
        isMix:this.isMix,
      }).then((response) => {
        let url = window.URL.createObjectURL(response.data);
        let link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);

        link.click();
      });
    },
    setDeliveryDate(i) {
      // get delivery date from data attribute of image in carousel
      var el = document.getElementById('carousel-img-' + i);
      if (el) {
        this.delivery_date = el.getAttribute('data-delivery-date');
      }
    },
    onSlideStart(slide) {
      this.setDeliveryDate(slide);
    },
    async setListingDetailsData() {
      let _this = this;

      let stagingRequestID = location.pathname.split(
        '/staging/delivered/correction/'
      )[1];
      let accessToken = localStorage.getItem('_accessToken');
      let page = _this.$store.state.currentPaginationPage;

      _this.stagingListing = null;
      _this.listingDetailsData = null;

      await cus_stagingService_GetSRData({
        id: stagingRequestID,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.stagingListing = data
        })
        .catch((error) => {
          console.log(error);
        });

      await cus_stagingService_GetAllSRItems({
        id: stagingRequestID,
        paginated: 1,
        page_size: 1,
        page: page,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.listingDetailsData = data.data[0];
          _this.pagination.data = data;
          _this.lastPage = _this.pagination.data.last_page

          // options
          _this.materialCount[_this.listingDetailsData.id] = _this.listingDetailsData.material_count ? _this.listingDetailsData.material_count : 0;
          let add = true;
          _this.additionalOptions.forEach((el) => {
            if (el.itemID == _this.listingDetailsData.id) {
              add = false;
            }
          });

          if (add) {
              _this.additionalOptions.push({
                itemID: _this.listingDetailsData.id,
                value: [],
              });
          }

          //get staging versions
          const { current_version, prev_versions } = getRequestItemVersions(
            data.data[0]
          );
          _this.current_version = current_version;
          for (var i=0;i<prev_versions.length;i++){
            if (prev_versions[i].user_type == undefined || prev_versions[i].user_type == 0 ){
              _this.old_versions.push(prev_versions[i]);
            }
          }
          _this.setCommentModel({
            request_item_id: data.data[0].id,
            image: current_version.result_file_url,
            comment: null,
          });
          _this.delivery_date = '';


          // オプションを取得する
          if (_this.stagingListing['request_category'] == 3) {
            _this.all_additionalOptions.loader = true;
            cus_stagingService_GetConfirmDeleteAditionalOptions({
              token: accessToken,
              request_item_id:data.data[0].id,
            })
            .then((response) => {
              let data = response.data;
              let additionalOptions = data.map((el) => {
                return {
                  text: el.name,
                  value: el.id,
                  price: el.charge,
                  can_select : el.can_select
                };
              });
              _this.all_additionalOptions.options = additionalOptions;
              _this.all_additionalOptions.loader = false;
            })
            .catch((error) => {
                console.log(error);
            });
          } else if (_this.stagingListing['request_category'] == 5) {
            _this.all_additionalOptions.loader = true;
            cus_stagingService_GetConfirmDigitalRenovationOptions({
              token: accessToken,
              request_item_id:data.data[0].id,
            })
            .then((response) => {
              let data = response.data;
              let additionalOptions = data.map((el) => {
                return {
                  text: el.name,
                  value: el.id,
                  price: el.charge,
                  can_select : el.can_select
                };
              });
              _this.all_additionalOptions.options = additionalOptions;
              _this.all_additionalOptions.loader = false;
            })
            .catch((error) => {
                console.log(error);
            });
          } else {
            _this.all_additionalOptions.loader = true;
            cus_stagingService_GetConfirmAditionalOptions({
              token: accessToken,
              request_item_id:data.data[0].id,
            })
            .then((response) => {
              let data = response.data;
              let additionalOptions = data.map((el) => {
                return {
                  text: el.name,
                  value: el.id,
                  price: el.charge,
                  can_select : el.can_select
                };
              });
              _this.all_additionalOptions.options = additionalOptions;
              _this.all_additionalOptions.loader = false;
            })
            .catch((error) => {
              console.log(error);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      
    },

    optionChange(itemId, option, mtCnt, elId) {
      let values = option.value;
      if (values.indexOf(6) < 0 && values.indexOf(5) < 0) {
          this.materialDisable[itemId] = false;
          this.delMaterialPdf(mtCnt, elId);
          return;
      }
      this.materialDisable[itemId] = true;
    },
    delMaterialPdf(count, id) {
      console.log(this.requestBaseDir);
      if (!this.requestBaseDir) {
        return;
      }
      let stagingRequestID = location.pathname.split(
        '/staging/delivered/correction/'
      )[1];
      let accessToken = localStorage.getItem('_accessToken');
      if (count > 0) {
        cus_stagingService_corrent_DeleteMaterial({
          item_id: id,
          sr_id: stagingRequestID,
          request_base_dir: this.requestBaseDir,
          token: accessToken,
        })
        .then(() => {
          this.materialCount[id] = 0;
          this.$forceUpdate();
        setTimeout(() => {
            this.$bvModal.hide('modal-loader');
        }, 500);
        })
        .catch((error) => {
        console.log(error);

        this.$bvToast.toast(
            '削除中にエラーが起こりました。再度お試しください。',
            {
            title: 'システムエラー',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: true,
            }
        );

        setTimeout(() => {
            this.$bvModal.hide('modal-loader');
        }, 500);
        });
      }
    },
    uploadMaterial(count, itemId) {
      if (count > 10) {
        return false;
      }

      if (typeof this.materialDisable[itemId] === "undefined") {
        return true;
      }
      return !this.materialDisable[itemId];
    },
    validateMaterialPdfs(id, e) {
      let _this = this;

      _this.formMessage = null;
      _this.materialLoadingItemId = id;

      let files = e.target.files;
      let filesErrorMessage = [];

      _this.materialUploadCount = files.length;

      if (files.length > 0) {
        if (files.length <= 20) {
          for (let i = 0; i < files.length; i++) {
            
            if (
              files[i].type === 'image/jpeg' ||
              files[i].type === 'image/png' ||
              files[i].type === 'application/pdf'
            ) {
              if (files[i].size <= 10000000) {
                console.log('Processing ...');
              } else {
                filesErrorMessage.push(
                  `ファイル名 (${files[i].name}) - 画像は 10 MB 未満である必要があります。`
                );

                _this.$parent.steps.proceedDisabled = false;
                _this.materialUploadCount = 0;
              }
            } else {
              filesErrorMessage.push(
                `<li>File Name (${files[i].name}) - Please upload a file type of JPEG, JPG or PNG or PDF.</li>`
              );

              _this.materialUploadCount = 0;
            }
          }
          
          setTimeout(() => {
            _this.validateMaterialPdfsProceed(_this, files, filesErrorMessage, id);
          }, 1000);
        } else { 
          _this.materialUploadCount = 0;
        }
      }
    },
    validateMaterialPdfsProceed(_this, files, filesErrorMessage, item_id) {

      let stagingRequestID = location.pathname.split(
        '/staging/delivered/correction/'
      )[1];
      let accessToken = localStorage.getItem('_accessToken');
      if (filesErrorMessage.length === 0) {
        for (let i = 0; i < files.length; i++) {
          _this.materialLoaderItems.push('-' + i);
          _this.materialNumber.push(_this.materialLoaderItems.length + i)
          let formData = new FormData();
          formData.append('sr_id', stagingRequestID);
          formData.append('item_id', item_id);

          if(files[i].type === 'image/jpeg' || files[i].type === 'image/png' ) {
            formData.append('img', files[i]);
          } else if(files[i].type === 'application/pdf') {
            formData.append('pdf', files[i]);
          }
          formData.append('index', _this.materialNumber.length);

          cus_stagingService_corrent_UpdateMaterial(
            {
              token: accessToken,
              request_base_dir: this.requestBaseDir,
            },
            formData
          )
            .then((response) => {
              if (response.data.result) {
                _this.materialLoaderItems.pop();
                _this.material.push(response.data.result);
                this.materialFileName = response.data.result
                this.materialCount[item_id] = this.materialCount[item_id] + 1;
                this.requestBaseDir = response.data.result.requestBaseDir;
                this.$forceUpdate();
              }
              _this.materialUploadCount -= 1;
              _this.materialLoadingItemId = null;
            })
            .catch((error) => {
              _this.materialUploadCount = 0;
              _this.formMessage = error;
              console.log(error);
            });
        }
      } else {
        _this.formMessage = filesErrorMessage.join('');
        _this.materialUploadCount = 0;
      }
    },
    setMaterialAddPdf(count, id) {
      if (count < 10) {
        let idName = 'material-uploader_' + id;
        let fileUploaders = document.querySelector('#' + idName);

        fileUploaders.value = null;
        fileUploaders.click();
      } else {
        this.formMessage =
        '<li>Maximum of 20 images only. Please remove image to add a new one.</li>';
      }
    },

    setCommentModel(obj) {
      let objExist = this.comments.find(
        (el) => el.request_item_id === obj.request_item_id
      );

      if (!objExist) {
        this.comments.push(obj);
      }
    },

    goToPrevious(from) {
      this.$store.state.currentPaginationPage = from -= 1;

      this.formMessage = null;
      this.summary = false;

      this.setListingDetailsData();
    },

    goToNext(from) {
      this.$store.state.currentPaginationPage = from += 1;

      this.setListingDetailsData();
    },

    setSummary() {
      this.summary = true;
      this.sumPrice = 0;

      this.summaryEmptyState = true;

      this.comments.forEach((el) => {
        if (el.comment) {
          this.summaryEmptyState = false;
        }
      });

      this.additionalOptions.forEach((option) => {
        if (option.value.length > 0) {
          this.summaryEmptyState = false;
        }
      });
      console.log(this.comments);

      let result = []
      for (let j = 0;j < this.lastPage; j++) {
        let options = this.all_additionalOptions.options;
        console.log(this.additionalOptions);
        console.log('options');
        options.forEach((option) => {
          if (this.additionalOptions[j].value.indexOf(option.value) >= 0) {
            if (result['itemId:' + this.additionalOptions[j].itemID.toString(10)] === undefined) {
              result['itemId:' + this.additionalOptions[j].itemID.toString(10)] = [];
            }
            result['itemId:' + this.additionalOptions[j].itemID.toString(10)].push({
              text: option.text + ' - ' + formatPrice(option.price),
              amount:option.price,
            })
            this.sumPrice += Number(option.price);
          }
        });
      }
      this.sumPrice *= 1.1;
      this.resOptions = result;
    },

    optionKey(id) {
      return 'itemId:' + id;
    },
    async dispAlertModalState() {
      this.isPayment = false;
      this.isUpdate = false;
      this.isPhoto = false;
      // 
      this.$store.state.modalLoaderMessage = '送信中';
      this.$bvModal.show('modal-loader');
      let items = [];
      for (let i = 0; i < this.lastPage; i++) {
        items.push({
          request_item_id: this.additionalOptions[i]['itemID'],
          additional_options: this.additionalOptions[i]['value'],
        })
      }
      let accessToken = localStorage.getItem('_accessToken');
      await cus_stagingService_corrent_UpdateConfirmOptions({
          items: items,
          token: accessToken,
      })
        .then((response) => {
          this.confirmCount = response.data.result.correction_count
          this.isInvoicePayment = response.data.result.is_invoice_payment

          setTimeout(() => {
            this.$bvModal.hide('modal-loader');
          }, 500);
        })
        .catch((error) => {
            console.log(error);

            setTimeout(() => {
              this.$bvModal.hide('modal-loader');
            }, 500);
          });

      this.$bvModal.show('confirm-modal');

      return false;
      
    },

    async checkAlertModalState() {
      if (!this.isPayment || !this.isUpdate || !this.isPhoto ) {
            return;
        }
        console.log('close', this.$parent);
        this.$store.state.staging.checkAlertModal = false;


        let payment = false;
        this.additionalOptions.forEach((option) => {
          if (option.value.length > 0) {
            payment = true;
          }
        });

        if (!payment) {
          this.submitCorrection();
          return;
        }

        let stagingRequestID = location.pathname.split(
          '/staging/delivered/correction/'
        )[1];
        let accessToken = localStorage.getItem('_accessToken');
        await cus_stagingService_CreateConfirmChekoutDetails({
          id: stagingRequestID,
          confirm_count: this.confirmCount,
          token: accessToken,
        })
        .then((response) => {
          console.log(response);
          console.log('response');
          this.summaryData = response.data.result;
        })
        .catch((error) => {
            console.log(error);
        });
        this.$bvModal.hide('confirm-modal');
        if (this.isInvoicePayment == 1) {
          this.showInvoiceModal();
        } else {
          this.showPaymentModal();
        }
    },

    async showPaymentModal() {
      this.$bvModal.show('payment-information-modal');

      setTimeout(() => {
        this.setStripeElements();
      }, 1000);
    },

    showInvoiceModal() {
      this.$bvModal.show('invoice-success-modal');
    },
    setStripeElements() {
      let style = {
        base: {
          height: '56',
          fontSize: '16px',
          lineHeight: '2.5',
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      this.cardNumber = this.stripeElements.create('cardNumber', {
        showIcon: true,
        style,
      });
      this.cardNumber.mount('#card-number');

      this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
      this.cardExpiry.mount('#card-expiry');

      this.cardCVC = this.stripeElements.create('cardCvc', { style });
      this.cardCVC.mount('#card-cvc');
    },

    destroyInvoiceElements() {
      this.$bvModal.hide('invoice-success-modal');
    },

    async finalizeStaging() {
      let _this = this;

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = '送信中';
      _this.$bvModal.show('modal-loader');

      if (_this.cardNumber) {
        if (_this.cardExpiry) {
          if (_this.cardCVC) {
            if (_this.cardName) {
              _this.$stripe
                .createPaymentMethod({
                  type: 'card',
                  card: _this.cardNumber,
                })
                .then(async (response) => {
                  let paymentMethodID = response.paymentMethod.id;
                  _this.$stripe
                    .confirmCardPayment(_this.summaryData.pi_client_secret, {
                      payment_method: paymentMethodID,
                    })
                    .then(async (response) => {
                      if (response.error) {
                        _this.isCardError = true;
                      } else {
                        _this.isCardError = false;
                        localStorage.removeItem('_stagingOfficeRequestID');
                        this.submitCorrection();
                      }
                      setTimeout(() => {
                        _this.$bvModal.hide('payment-information-modal');
                        _this.$bvModal.hide('modal-loader');
                        _this.$bvModal.show('payment-success-modal');
                      }, 500);
                    })
                    .catch((error) => {
                      console.log(error);

                      _this.formMessage =
                        'エラーが起こりました。再度お試しください。1';

                      setTimeout(() => {
                        _this.$bvModal.hide('modal-loader');
                      }, 500);
                    });
                })
                .catch((error) => {
                  console.log(error);
                  _this.formMessage =
                    'エラーが起こりました。再度お試しください。2';

                  setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                  }, 500);
                });
            } else {
              _this.formMessage = 'カード名義が必要です。';

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);
            }
          } else {
            _this.formMessage = 'CVCが必要です。';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          }
        } else {
          _this.formMessage = '有効期限が必要です。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        }
      } else {
        _this.formMessage = 'クレジットカード番号が必要です。';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
      } 
    },
    destroyStripeElements() {
      this.$bvModal.hide('payment-information-modal');

      this.cardNumber.destroy();
      this.cardExpiry.destroy();
      this.cardCVC.destroy();
    },
    async invoiceSuccessModal() {
      let accessToken = localStorage.getItem('_accessToken');
      let stagingRequestID = location.pathname.split(
        '/staging/delivered/correction/'
      )[1];
      await cus_stagingService_successConfirmInvoicePayment({
        id: stagingRequestID,
        confirm_count: this.confirmCount,
        token: accessToken,
      });
      this.submitCorrection();
    },

    async submitCorrection() {

      this.$store.state.modalLoaderMessage = '送信中';
      this.$bvModal.show('modal-loader');

      let items =[];
      let commentItems = [];
      this.comments.forEach((comment) => {
        if (comment.comment) {
          commentItems['itemId:' + comment['request_item_id']] = comment['comment'];
        } else {
          commentItems['itemId:' + comment['request_item_id']] = null;
        }
      });
      for (let i = 0; i < this.lastPage; i++) {
        if (this.additionalOptions[i]['value'].length > 0 || commentItems['itemId:' + this.additionalOptions[i]['itemID']]) {
          items.push({
            request_item_id: this.additionalOptions[i]['itemID'],
            additional_options: this.additionalOptions[i]['value'],
            comment: commentItems['itemId:' + this.additionalOptions[i]['itemID']]
          });
        }
      }

      let accessToken = localStorage.getItem('_accessToken');

      if (items.length > 0) {
        await cus_stagingService_CreateCR({
          items: items,
          request_base_dir: this.requestBaseDir,
          token: accessToken,
        })
          .then((response) => {
            console.log(response);

            setTimeout(() => {
              this.$bvModal.hide('modal-loader');
              this.$router.push({
                name: 'userRoute-staging-delivered',
              });
            }, 500);
          })
          .catch((error) => {
            console.log(error);

            setTimeout(() => {
              this.$bvModal.hide('modal-loader');
            }, 500);
          });
      }
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
.details-title {
  font-size: 19px;
  font-style: normal normal medium 19px/23px Roboto;
}
.details-title {
  margin-bottom: 43px;
}
.details span,
.details p {
  font-size: 16px;
}
#uploaded-image {
  margin-top: 40px;
}
#uploaded-image .image {
  height: 340px;
  background: #ececec;
  border-radius: 15px;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  object-fit: cover;
}
.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
}
.summary-item .image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  max-height: 300px;
}

.summary-item .details {
  padding-top: 10px;
}

.download-wrapper {
  position: absolute;
  right: 36px;
  bottom: 12px;
  background: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.download-wrapper.old-version-carousel {
  right: -40px;
  bottom: -8px;
}

.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
}

@media only screen and (max-width: 1440px) {
  #uploaded-image .image {
    height: 230px;
  }
  .summary-item .image img {
    height: 200px;
  }
  .download-wrapper.old-version-carousel {
    right: -20px;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .image {
    height: 400px;
  }
}

@media only screen and (max-width: 425px) {
  #uploaded-image .image {
    height: 250px;
  }
}

#uploaded-image .image img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
}

#uploaded-image .image .img-carousel {
  height: 100%;
  border-radius: 15px;
  background: #e9e9e9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#uploaded-image .timestamp {
  margin: 10px 0 0 20px;
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .timestamp {
    margin: 10px 0 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .details {
    margin-top: 60px;
  }
}

#comments {
  margin-top: 40px;
}

@media only screen and (max-width: 1199px) {
  #comments {
    margin-top: 20px;
  }
}

.actions {
  margin-top: 40px;
  text-align: right;
}

.actions .btn {
  width: 200px;
  margin-left: 40px;
}

@media only screen and (max-width: 767px) {
  .actions {
    text-align: center;
  }

  .actions .btn {
    width: 100%;
    margin-left: 0;
  }
}

.summary-item {
  margin: 20px 2px 2px 2px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: #f5f5f5;
}

.summary-item:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.summary-item .image {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .summary-item .image {
    margin-bottom: 20px;
  }
}
</style>
